<template>
  aaa
  {{ o.name }}
</template>

<script>

export default {
  props: ['o']
}
</script>
