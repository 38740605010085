<template>
  
  <n-space v-if='this.show_debug' justify="center" class="debug">
    <b>CHARTS:</b>
    url_filter: {{ url_filter }}
  </n-space>
  <n-space justify="center">
   statusy: <n-switch v-model:value="show_chart1" />
   ilości w czasie - linia <n-switch v-model:value="show_chart2" />
   słupki <n-switch v-model:value="show_chart3" />
   
  </n-space>
  <n-space justify="center">
    <h4>Pierścieniowy ze statusami</h4>
  </n-space>
  <n-space justify="center">
    <Chart_Pie v-if="show_chart1" v-bind:chartData="this.statusy.chartData" v-bind:chartOptions="this.statusy.chartOptions" style='width: 900px; height: 300px; border: 1px #cccccc solid;'/>
  </n-space>
  <hr><n-space justify="center">
    <h4>Liniowy i słupkowy z ilością w czasie</h4>
  </n-space>
  <n-space justify="center">
    <n-radio-group v-model:value="gb" name="radiobuttongroup1" type="primary">
    <n-radio-button 
      v-for="cgb in chart_group_by"
      :key="cgb.label"
      :value="cgb.value">
      {{ cgb.label }}
    </n-radio-button>
  </n-radio-group>
  </n-space>
  <n-space justify="center">
    <Chart_Line v-if="show_chart2" v-bind:chartData="this.ilosci.chartData" v-bind:chartOptions="this.ilosci.chartOptions" style='width: 900px; height: 300px; border: 1px #cccccc solid;'/>
  </n-space>
  <n-space justify="center">
    <Chart_Bar v-if="show_chart3" v-bind:chartData="this.ilosci2.chartData" v-bind:chartOptions="this.ilosci2.chartOptions" style='width: 900px; height: 300px; border: 1px #cccccc solid;'/>
  </n-space>
</template>

<script>
import Chart_Pie from "./Chart_Pie.vue"
import Chart_Line from "./Chart_Line.vue"
import Chart_Bar from "./Chart_Bar.vue"
import { NSpace, NSwitch, NRadioGroup, NRadioButton } from "naive-ui"

export default {
  props: ['show_debug', 'url_filter'],
  components: {
    Chart_Pie, NSpace, NSwitch, Chart_Line, Chart_Bar, NRadioGroup, NRadioButton
  },
  data() {
    return {
      gb: 'daily',
      show_chart1: false,
      show_chart2: false,
      show_chart3: false,
      chart_group_by: [
        { value: 'daily', label: 'dziennie' },
        { value: 'week', label: 'tygodniami' },
        { value: 'month', label: 'miesięcznie' },
      ],
      statusy: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: []
            },
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      },
      ilosci: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderColor: '',
              fill: false
            },
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          
        }
      },
      ilosci2: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: ['rgba(255, 99, 132, 0.3)'],
              borderColor: ['rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'],
              borderWidth: 3
            },
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          
        }
      }      
    }
  },
  watch: {
    url_filter: function() {
      this.loadChartData()
    },
    gb: function() {
      this.loadChartData()
    }
  },
  methods: {
    loadChartData() {
      this.show_chart1 = false
      
      fetch('https://api.sabrez.pl/api/v1/exo/chart_statusy?' + this.url_filter )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.statusy.chartData.labels = Object.keys(res)
        this.statusy.chartData.datasets[0].data = Object.values(res)
        // this.chartData.datasets[0].data.forEach(function() { parent.chartData.datasets[0].backgroundColor.push(Math.floor(Math.random()*16777215).toString(16)) })
        for( let i=10; i--; ) this.statusy.chartData.datasets[0].backgroundColor.push("#"+Math.floor(Math.random()*16777215).toString(16))
        // console.log(Object.values(res))
        // console.log('CHARTS: dane z chartData ' + this.statusy.chartData.labels)
        // console.log('CHARTS: dane z chartData ' + this.statusy.chartData.datasets[0].data)
        this.show_chart1 = true
        // console.log('mounted '+this.show_chart1)
      });
      this.show_chart2 = false
      var gb = '' 
      // if ( gb == 'week' ) gb = '&gb=week'
      // if ( gb == 'month' ) gb = '&gb=month'
      fetch('https://api.sabrez.pl/api/v1/exo/chart_count?' + this.url_filter + '&gb=' + this.gb )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.ilosci.chartData.labels = Object.keys(res)
        this.ilosci.chartData.datasets[0].data = Object.values(res)
        this.ilosci.chartData.datasets[0].label = 'Zwroty w czasie'
        this.ilosci.chartData.datasets[0].backgroundColor = '#007755'
        this.ilosci.chartData.datasets[0].borderColor = '#007755'
        // this.chartData.datasets[0].data.forEach(function() { parent.chartData.datasets[0].backgroundColor.push(Math.floor(Math.random()*16777215).toString(16)) })
        // for( let i=100; i--; ) this.ilosci.chartData.datasets[0].backgroundColor.push("#"+Math.floor(Math.random()*16777215).toString(16))
        // console.log(Object.values(res))
        // console.log('CHARTS: dane z chartData ' + this.ilosci.chartData.labels)
        // console.log('CHARTS: dane z chartData ' + this.ilosci.chartData.datasets[0].data)
        this.show_chart2 = true
        // console.log('mounted '+this.show_chart2)
      });
      this.show_chart3 = false
      var url = 'https://api.sabrez.pl/api/v1/exo/chart_count?' + this.url_filter + '&gb=' + this.gb
      console.log(url)
      fetch( url )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.ilosci2.chartData.labels = Object.keys(res)
        this.ilosci2.chartData.datasets[0].data = Object.values(res)
        this.ilosci2.chartData.datasets[0].label = 'Zwroty w czasie'
        this.ilosci2.chartData.datasets[0].borderColor = []
        this.ilosci2.chartData.datasets[0].backgroundColor = []
        // this.ilosci2.chartData.datasets[0].borderColor.push('#007755')
        // this.ilosci2.chartData.datasets[0].backgroundColor.push('#007755')
        var array_length = this.ilosci2.chartData.datasets[0].data.length
        for( let i=array_length; i--; ) {
          var bar_color = Math.floor(Math.random()*100)+", "+Math.floor(Math.random()*155+100)+", "+Math.floor(Math.random()*100)
          this.ilosci2.chartData.datasets[0].borderColor.push("rgb("+bar_color+")")
          this.ilosci2.chartData.datasets[0].backgroundColor.push("rgba("+bar_color+",0.3)")
        }          
        // this.chartData.datasets[0].data.forEach(function() { parent.chartData.datasets[0].backgroundColor.push(Math.floor(Math.random()*16777215).toString(16)) })
        // for( let i=100; i--; ) this.ilosci.chartData.datasets[0].backgroundColor.push("#"+Math.floor(Math.random()*16777215).toString(16))
        // console.log(Object.values(res))
        // console.log('CHARTS: dane z chartData ' + this.ilosci.chartData.labels)
        // console.log('CHARTS: dane z chartData ' + this.ilosci.chartData.datasets[0].data)
        this.show_chart3 = true
        // console.log('mounted '+this.show_chart2)
      });
    }
  },
  setup() {
  },
  mounted() {
    this.loadChartData()
    // console.log('mounted'+this.show_chart1)
    console.log('mounted'+this.url_filter)
    // this.show_chart1 = true
  }
}
</script>