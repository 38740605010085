<template>
 <h1>Status page</h1>
  <n-button>asd</n-button>
  <br>
</template>

<script>
  import { NButton } from 'naive-ui'

  export default {
    name: 'Status',
    components: {
      NButton
    }
  }
</script>
