import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Status from '../views/Status.vue'
import SignIn from '../views/SignIn.vue'
import Dashboard from '../views/Dashboard.vue'
import Organisations from '../views/Organisations.vue'
import NotFound from '../views/NotFound.vue'
import About from '../views/About.vue'
import lm_dashboard from '../views/LM_dashboard.vue'
import store from '@/store'

const routes = [
  { path: '/',                name: 'Home',           component: Home             },
  { path: '/signin',          name: 'SignIn',         component: SignIn           },
  { path: '/about',           name: 'About',          component: About            },
  { 
    path: '/lm',
    name: 'lm_dashboard',
    component: lm_dashboard,
    beforeEnter: (to,from,next) => {
      AuthorizedGuard(to,from,next)
    }
  },
  { 
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: (to,from,next) => {
      AuthorizedGuard(to,from,next)
    }
  },
  { 
    path: '/organisations',
    name: 'Organisations',
    component: Organisations,
    beforeEnter: (to,from,next) => {
      AuthorizedGuard(to,from,next)      
    }
  },
  { 
    path: '/status',
    name: 'Status',
    component: Status
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

function AuthorizedGuard(to,from,next) {
  if (!store.getters['auth/authenticated']) {
        return next({
          name: 'SignIn'
        })
      } else {
        console.log(store.getters['auth/authenticated'])
        next()
      }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
