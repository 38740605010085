<template>
 Home
</template>

<script>


export default {
  name: 'Home',
  components: {
    
  }
}
console.log('Home')
</script>
