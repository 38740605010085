<template>
<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container-fluid">
    <router-link class="navbar-brand" :to="{name: 'About'}">SaB</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">Home</a>
        </li>
        <template v-if="authenticated">
        <!-- <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'Dashboard'}">Dashboard</router-link>
        </li> -->
        <!-- <li class="nav-item">
          <router-link  class="nav-link" :to="{name: 'Organisations'}">Organisations</router-link>
        </li>  -->
        

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Exorigo-Upos
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link class="dropdown-item" :to="{name: 'lm_dashboard'}">Dashboard LM</router-link></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link disabled">Disabled</a>
        </li> -->
        </template>
      </ul>
      <template v-if="authenticated">
        <!-- <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
          <!-- <button class="btn btn-outline-success" @click="signOut">LogOut</button> -->
          <n-button strong  @click="signOut" size="medium" type="primary">logOut</n-button>
        </template>
      <template v-else>
        <!-- <button class="btn btn-outline-success" @click="signIn">LogIn2</button> -->
         <!-- <n-button  @click="manageModal(true)" size="medium" type="default">logIn</n-button> -->
         <n-button  @click="manageModal(true)" size="medium" type="primary">logIn</n-button>
        <!-- <button class="btn btn-outline-success" @click="manageModal(true)">Zaloguj</button> -->
      </template>
    </div>
  </div>
</nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { NButton } from 'naive-ui'
export default {
  components: { 
      NButton,
  },
  name: "TheNavigation",
  emits: ["manageLoginDialog"],
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  setup() {
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: 'SignIn'
        })
      })
    },
    signIn() {
      this.$router.replace({
        name: 'SignIn'
      })
    },
    manageModal(mode = false) {
      console.log(mode);
      // console.log(isOpen);
      // this.isOpen = false;
      this.$emit('manageLoginDialog',mode)
    }
  }
};
</script>

<style>
nav.navbar {
  background-color: #2a2a2a;
};
</style>