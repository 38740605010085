<template>
 <h3>Zaloguj się do systemu</h3>
 <!-- <div class="border m3">
  <ui-form nowrap class="conditions-form" item-margin-bottom="16" action-align="center" >
    <template #default="{ subitemClass, actionClass }">
    <ui-form-field>
      <label class="required" style='width: 200px'>Użytkownik: </label>
      <ui-textfield v-model="form.username" required outlined>twój identyfikator</ui-textfield>
    </ui-form-field>
    <ui-form-field>
      <label class="required right" style='width: 200px; text-align: right;' >Hasło: </label>
    <ui-textfield
      v-model="form.password" input-type="password" required outlined>super tajne hasło</ui-textfield>
    </ui-form-field>
    <ui-form-field :class="actionClass">
      <ui-button raised @click="submit">Submit</ui-button> 
      <ui-button outlined>Cancel</ui-button>
    </ui-form-field>
    </template>
   </ui-form>

 </div> -->
  
  <!-- <form @submit.prevent="submit">
   <div>
     <label for="username"></label>
     <input type="text" name="username" id="username" v-model="form.username">
   </div>
   <div>
     <label for="password"></label>
     <input type="password" name="password" id="password" v-model="form.password">
   </div>
   <div>
     <button type="submit">Sign in</button>
   </div>
 </form>
  <hr>
  dane z forumularza: {{ form }} -->
</template>

<script>

import { mapActions } from 'vuex'

export default {

  name: 'SignIn',
  components: {
    
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    submit () {
      this.signIn(this.form).then(() => {
        this.$router.replace({
          name: 'Dashboard'
        })
      }).catch(() => {
        console.log('failed')
      })
    }
    
  }
}
</script>
