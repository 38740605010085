<template>
   <n-grid cols="3 s:3 m:3 l:5 xl:5 2xl:5" responsive="screen">
    <n-grid-item span="0 m:0 l:1">
    </n-grid-item>
    <n-grid-item>
      <h5>Sklep:</h5>
      <n-select multiple v-model:value="sklepy" :options="lista_sklepow" placeholder="wybierz sklep"/>
    </n-grid-item>
    <n-grid-item>
      <h5>Zakres dat:</h5>
      <n-date-picker v-model:value="date_range" type="daterange" clearable />
    </n-grid-item>
    <n-grid-item>
      <h5>Status:</h5>
      <n-select multiple v-model:value="statusy" :options="lista_statusow" placeholder="wybierz statusy" />
    </n-grid-item>
  </n-grid>
  <n-grid cols="3 s:3 m:3 l:5 xl:5 2xl:5" responsive="screen">
    <n-grid-item span="1 m:1 l:2">      
    </n-grid-item>
    <n-grid-item class="text-center">
      <n-button  @click="updateFilters()" size="medium" type="primary">zastosuj filtry</n-button>
    </n-grid-item>
  </n-grid>
  <hr>
</template>
<script>

  import { NSelect, NRow, NCol, NDatePicker, NGrid,NGridItem, NButton } from 'naive-ui'

  export default {
    props: ['statusy','lista_statusow','date_range','lista_sklepow','sklepy', 'show_debug'],
    emits: ["update", "update:statusy", "update:sklepy", "update:date_range"],
    components: {
      NButton, NDatePicker, NGrid, NRow, NCol, NGridItem, NSelect
    },
    methods: {
      updateFilters() {
        this.offset = 1;
        this.$emit('update:statusy', this.statusy)
        this.$emit('update:date_range', this.date_range)
        this.$emit('update:sklepy', this.sklepy)
        this.$emit('update')
      }
    }
  }
</script>
