<template >
  <n-modal v-model:show="isOpen" :show-icon="false" preset="dialog" title="OKNO LOGOWANIA" v-model:on-after-leave="manageModal">
    <!-- <n-space vertical> -->
      <n-form-item label="Nazwa użytkownika" path="form.username">
        <n-input type="text" 
          v-model:value="form.username" 
          placeholder="username" 
          :maxlength="40"
        />
      </n-form-item>
      <n-form-item label="Supertajne hasło" path="form.username">
        <n-input
          type="password"
          v-model:value="form.password" 
          show-password-on="mousedown"
          placeholder="....."
          :maxlength="40"
        /> 
      </n-form-item>
      <n-space justify="center" size="large">
      <n-button strong @click="tryLogin" size="small" type="default">Zaloguj</n-button> 
      <n-button strong type="default" size="small" @click="manageModal(false)">Anuluj</n-button>
    </n-space>
  </n-modal>
  <!-- <n-button @click="manageModal(true)" >WYŚWIETL</n-button>
  <n-button @click="manageModal(false)">ZAMKNIJ</n-button>
  <n-button @click="warning()">ZAMKNIJ</n-button>
  MODAL [{{ isOpen }}, {{ user }}] -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { useDialog, NTag, NModal, NButton, NInput, NSpace, NFormItem, useNotification  } from 'naive-ui'
// import { defineComponent, ref } from 'vue'


export default {

  components: {
    NButton, useDialog, NModal, NTag, NInput, NSpace, NFormItem, useNotification
  },
  props: ['isOpen'],

  name: "LoginDialog",

  emits: ["manageLoginDialog"],

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  setup() {
    const notification = useNotification()
    return {
      notify(message = 'Podczas wykonywania operacji... Wystąpił błąd!', notifyType = 'info', duration = 5000) {
        console.log('start');
        notification[notifyType]({
          title: 'Co by Ci tu powiedzieć?',
          description: message,
          duration: duration
        })
        console.log('end');
      }
    }
  },
  data() {
    return {
      // open: this.isOpen,
      // dupa: 1,
      // isOpen: ref(false),
      form: {
        username: '',
        password: ''
      },
      notify (type) {
        console.log(useNotification);
        this.warning('error','komunikat')
        // notification.warning({
        //   content: 'What to say?',
        //   meta: "I don't know"
        // })
      },
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    tryLogin() {
      this.signIn(this.form).then(() => {
        this.$router.replace({
          name: 'Dashboard'
        });
        this.$emit('manageLoginDialog',false);
        console.log('login success')   
      }).catch(() => {
        console.log('login failed');
        // this.notify('success')
        this.notify('Prawdopodobnie podałeś nieprawidłowe dane podczas logowania!', 'error', 6000)
      })
    },
    // onCancel() {
      
    // },
    // showModal() {
    //   this.isOpen = true;
    // },
    manageModal(mode = false) {
      // console.log(mode);
      // console.log(isOpen);
      // this.isOpen = false;
      this.$emit('manageLoginDialog',mode)
    }
  }
}


</script>
