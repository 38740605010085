import axios from "axios"

export default {
  namespaced: true,
  state: {
    auth_token: null,
    username: null
  },

  getters: { 
    //
    authenticated ( state ) {
      let r = false
      if ( state.auth_token && state.username ) {
        r = true
      }
      return r
    },

    user ( state ) {
      return state.username
    }
  },

  mutations: {
    SET_TOKEN ( state, auth_token) {
      state.auth_token = auth_token
    },
    SET_USER ( state, data ) {
      state.username = data
    }
  },
  
  actions: {
    async signIn( { dispatch }, credentials ) {
      let response = await axios.post('https://api.sabrez.pl/api/v1/authenticate', credentials)

      return dispatch('attempt', response.data.auth_token)
    },
    async attempt( { commit }, auth_token ) {
      commit('SET_TOKEN', auth_token) 
      
      try {
        let response = await axios.get('https://api.sabrez.pl/api/v1/me')

        commit('SET_USER', response.data.user)
      } catch (e) {
        commit('SET_TOKEN', null )
        commit('SET_USER', null )
      }
    },
    signOut( { commit } ) {
      return axios.post('https://api.sabrez.pl/api/v1/signout').then(() => {
        commit('SET_TOKEN', null )
        commit('SET_USER', null )
      })
    } 
  }
}