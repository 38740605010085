<template>
  <div class="text-center">
    <h1>Dashboard LM</h1>
    </div>
  <n-space align="center" justify="center" >
    opcje widoku - filtry: <n-switch v-model:value="show_filters" />
     tabelka: <n-switch v-model:value="show_table">
      <template #checked>Przełącz do wykresów</template>
      <template #unchecked>Wróć do widoku tabeli</template>
     </n-switch>
     debug: <n-switch v-model:value="show_debug" />
  </n-space>
  <hr>
  <n-space v-if='show_debug' justify="center"  class="debug" >
    <b>MAIN: </b>ilość: {{ this.ilosc }}, stron: {{ this.pages }}, strona: {{ this.offset }}, limit: {{ this.limit }}, sklepy: {{ this.sklepy }}, statusy: {{ this.statusy }}, pages: {{ this.pages }},
    url_filter: '{{ this.url_filter }}'
  </n-space>
  <!-- <n-space justify="center"> -->
  <Filtry v-if='show_filters' @update="updateFilters" 
    v-on:update:sklepy="sklepy = $event" 
    :sklepy="sklepy"
    v-on:update:statusy="statusy = $event" 
    :statusy="statusy" 
    v-on:update:date_range="date_range = $event" 
    :date_range="date_range" 
    :lista_statusow="lista_statusow" 
    :lista_sklepow="lista_sklepow"
    :show_debug="show_debug"
    />
  <!-- </n-space> -->
  <Tabela v-if='show_table' 
    :show_debug="show_debug" 
    :pages="pages" 
    :list="list" 
    v-bind:offset="offset" 
    v-on:update:offset="offset = $event"
    />
  <n-space v-if='show_debug' justify="center" class="debug">
    <b>MAIN:</b> <n-button strong type="default" size="small" @click="offset++">main+</n-button>
    {{ offset }}
    </n-space>
  <Charts v-if='!show_table&&data_loaded' v-bind:url_filter="url_filter" :show_debug="show_debug" />

</template>
<script>

import Filtry from "../components/lm/Filtry.vue"
import Tabela from "../components/lm/Tabela.vue"
import Charts from "../components/lm/Charts.vue"
import { defineComponent, reactive, ref} from 'vue'
import { NSelect, NRow, NCol, NDatePicker, NGrid, NGridItem, NButton, NSwitch, NSpace } from 'naive-ui'

export default {
  name: 'LM_dashboard',
  data() {
    return {
      show_table: false,
      show_filters: true,
      show_debug: false,
      list: [],
      ilosc: 0,
      limit: 15,
      offset: ref(1),
      // date_from: null,
      // date_to: null,
      url_filter: '',
      pages: 1,
      sklepy: ref([]),
      statusy: ref([]),
      lista_sklepow: [],
      lista_statusow: [],   
      date_range: ref([(Date.now()-(30*24*60*60*1000)), Date.now()]),
      data_loaded: false
      
    }
  },
  components: { 
      NSelect, NRow, NCol,NDatePicker,NGrid,NGridItem,NButton, Charts, Filtry, Tabela, NSwitch, NSpace
  },
  computed: {
    
  },
  watch: {
    offset: function() {
      // console.log('update offset');
      this.loadData()
    }
  },
  methods: {
    ilePagin() {
      var pages = Math.floor((this.ilosc/this.limit)+0.9)
      if ( pages == 0 ) pages = 1;
      this.pages = pages;
      // console.log('pages:' + pages + ' ' + this.ilosc);
    },
    loadData(page = null) {
      if ( page != null ) {
        this.offset = page
      }
      const t = this;
      var str = 'limit=' + this.limit + '&offset=' + (this.limit*(this.offset-1))
      if ( this.date_range != null ) {
        str += '&data_range='+JSON.stringify(this.date_range)
      }
      if ( this.sklepy != [] ) {
        str += '&sklepy='+JSON.stringify(this.sklepy)
      }
      if ( this.statusy != [] ) {
        str += '&statusy='+JSON.stringify(this.statusy)
      }
      this.url_filter = str;
      // console.log(str)
      // console.log(this.sklepy)
      fetch('https://api.sabrez.pl/api/v1/exo/index?' + str )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.list = JSON.parse(JSON.stringify(res));
        });
      fetch('https://api.sabrez.pl/api/v1/exo/count?' + str )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.ilosc = (res);
          this.ilePagin();
        });
        this.data_loaded = true
        
    },
    loadShops() {
      fetch('https://api.sabrez.pl/api/v1/exo/sklepy')
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.lista_sklepow = JSON.parse(JSON.stringify(res));
        });
    },
    loadStatuses() {
      fetch('https://api.sabrez.pl/api/v1/exo/statusy')
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.lista_statusow = JSON.parse(JSON.stringify(res));
        });
    },
    updateFilters() {
      this.offset = 1;
      this.loadData();
    }
  },
  mounted() {
    this.loadData()
    this.loadShops()
    this.loadStatuses()
  },
  setup() {

  }
}
</script>
<style>
.debug {
  border: 1px salmon solid;
  margin: 1px;
  padding: 1px;
  font-size: 10px;
  background-color: rgba(255, 255, 0, 0.1);
}
</style>