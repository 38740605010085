import store from '@/store'
import axios from 'axios'
store.subscribe((mutation) => {
  switch(mutation.type) {
    case 'auth/SET_TOKEN':
      console.log(mutation.payload)
      if (mutation.payload) {
        axios.defaults.headers.common['Authorization'] = `Baarer ${mutation.payload}`
        localStorage.setItem('auth_token', mutation.payload)
      } else {
        axios.defaults.headers.common['Authorization'] = null
        localStorage.removeItem('auth_token')
      }
      break;

  }
})