<template>
  <n-space justify="space-around" size="large">
    <n-pagination 
      v-model:page="pg" 
      v-model:page-count="this.pages"
      show-quick-jumper
      />
  </n-space><n-space justify="space-around" size="large">
    <n-data-table
      ref="table"
      :columns="columns"
      :data="list"
      :bordered="true"
      size="small"
    />
  </n-space>
  <n-space justify="space-around" size="large">
    <n-pagination 
      v-model:page="pg" 
      v-model:page-count="this.pages"
      show-quick-jumper
      />
  </n-space>
  <n-space v-if='show_debug' justify="center" class="debug">
    lokalny offset{{ offset }}, lokalny pg {{ pg }}
    <n-button strong type="default" size="small" @click="offset++">offset child+</n-button>
    <n-button strong type="default" size="small" @click="offset--">offset child-</n-button>
    <n-button strong type="default" size="small" @click="pg++">pg+</n-button>
    <n-button strong type="default" size="small" @click="pg--">pg-</n-button>
  </n-space>
</template>

<script>
import { defineComponent, reactive, ref} from 'vue'
import { NDataTable, NButton, NSpace, NPagination } from "naive-ui"


export default {
  name: 'Tabela',
  props: ['pages', 'offset', 'list', 'strona','show_debug'],
  components: {
    NDataTable, NSpace, NPagination, NButton
  },
  data() {
    return {
      pg: ref(1),
      columns: [
        { title: 'ID', key: 'id' },
        { title: 'SKLEP', key: 'nr_sklepu'},
        { title: 'Nr transakcji', key: 'nr_transakcji' },
        { title: 'Nr zamówienia', key: 'nr_zamowienia' },
        { title: 'Kwota zwrotu', key: 'kwota_zwrotu' },
        { title: 'Pozostała zaliczka', key: 'pozostajaca_zaliczka' },
        { title: 'Forma płatności', key: 'forma_platnosci' },
        { title: 'Numer karty/rachunku', key: 'nr_karty_rachunku' },
        { title: 'Data zwrotu', key: 'data_zwrotu' },
        { title: 'Data statusu', key: 'data_statusu' },
        { title: 'Status', key: 'status' },
        { title: 'Komentarz', key: 'komentarz' },
      ]
    }
  },
  emits: ["update:offset"],
  watch: {
    pg: function() {
      console.log(this.pg)
      this.$emit('update:offset', this.pg)
    }
  }
}
</script>