<template>
  <n-config-provider :theme="null">
    <the-navigation @manageLoginDialog="manageLoginWindow" />
    <n-notification-provider>
      <div class='container'>
        <!-- <router-view/> -->
        <login-dialog  :isOpen="showLoginWindow"  @manageLoginDialog="manageLoginWindow" />
      </div>
      <router-view></router-view>
    </n-notification-provider>
    </n-config-provider>
</template>

<script>
  
  import { mapGetters, mapActions } from "vuex";
  import TheNavigation from './components/TheNavigation.vue'
  import LoginDialog from './components/LoginDialog.vue'
  import { NButton, NNotificationProvider, NConfigProvider, darkTheme } from 'naive-ui'
  
  export default {
    components: { 
      TheNavigation, LoginDialog, NButton, NNotificationProvider, NConfigProvider, darkTheme
    },
    setup() {
      return {
        darkTheme
      }
    },
    data() {
      return {
        isLogedIn: this.authenticated,
        showLoginWindow: false,
        globalCounter: 0
      }
    },
    computed: {
      ...mapGetters({
        authenticated: "auth/authenticated",
        user: "auth/user",
      }),
    },
    methods: {
      manageLoginWindow(LoginWindowState) {
        console.log("Set Display Login Window to: " + LoginWindowState);
        this.showLoginWindow = LoginWindowState;
        this.globalCounter++;
      }

    }
  }
</script>

<style>
  body {
    background: #fff;
  }
</style>
