<template>
  FIRMY:
  <div v-if="organisations.length">
    <div v-for="organisation in organisations">
      <Organisation :o="organisation"/>
    </div>
  </div>
  <div v-else>

    <div class="d-flex align-items-center">
  <strong>Loading...</strong>
  <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
</div>
    <!-- <div class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div> -->
  </div>
  
 <!-- {{ organisations }} -->
</template>

<script>
import axios from 'axios'
import Organisation from '../components/Organisation.vue'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export default {
  name: 'Organisations',
  components: {
    Organisation
  },
  data() {
    return {
      organisations: []
    }
  },
  mounted () {
    sleep(1000).then(() => { 
    axios
      .get('https://api.sabrez.pl/api/v1/organisations')
      .then( response => (this.organisations = response.data) )
    })
  }  
}
</script>
