import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Chartkick from 'vue-chartkick'
// import Chart from 'chart.js/auto'
// import { Chart, registerables } from 'chart.js';

// Chart.register(...registerables);

// import Vue from 'vue'



// import BalmUI from 'balm-ui'; // Official Google Material Components
// import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
// import 'balm-ui-css';

require('@/store/subscriber')

// Vue.use(Chartkick.use(Chart))

store.dispatch('auth/attempt', localStorage.getItem('auth_token')).then(() => {
createApp(App).use(store).use(router).mount('#app')
})


